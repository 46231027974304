/**
 * High level router.
 *
 * Note: It's recommended to compose related routes in internal router
 * components (e.g: `src/app/modules/Auth/pages/AuthPage`, `src/app/BasePage`).
 */

import {FC} from 'react'
import {Routes, Route, BrowserRouter, Navigate} from 'react-router-dom'
import {ErrorsPage} from '../modules/errors/ErrorsPage'
import {Logout, AuthPage} from '../modules/auth'
import {App} from '../App'
import { SellerOfferRoutes } from './SellerOfferRoutes'
import { LoginWithSeller } from '../modules/auth/components/LoginWithSeller'
import { AuthLayout } from '../modules/auth/AuthLayout'

/**
 * Base URL of the website.
 *
 * @see https://facebook.github.io/create-react-app/docs/using-the-public-folder
 */
const {PUBLIC_URL} = process.env

const SellerAllRoutes: FC = () => {
  // const {currentUser} = useAuth()
  const currentUser = localStorage.getItem("authtoken")
  return (
    <BrowserRouter basename={PUBLIC_URL}>
      <Routes>
        <Route element={<App />}>
          <Route path='error/*' element={<ErrorsPage />} />
          <Route path='logout' element={<Logout />} />
          {currentUser ? (
            <>
              <Route path='/*' element={<SellerOfferRoutes />} />
              <Route index element={<Navigate to='/webpage-seller/seller-offer' />} />
            </>
          ) : (
            <>
              <Route element={<AuthLayout />}>
                <Route path='auth' element={<LoginWithSeller />} />
                <Route index element={<LoginWithSeller />} />
              </Route>
              <Route path='*' element={<Navigate to='/auth' />} />
            </>
          )}
        </Route>
      </Routes>
    </BrowserRouter>
  )
}

export {SellerAllRoutes}
